import React, { useState, useEffect } from "react";

const Test = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function repeatFetch(){
    fetch("https://blm4nhm9j3.execute-api.ap-northeast-1.amazonaws.com/items")
      .then((res) => res.json())
      .then((json) => setData(json))
      .catch(() => alert("error"));
    }
    
    repeatFetch();
    
    const interval = setInterval(() => {
      repeatFetch();
    }, 10000);
    
  }, []);

  console.log(data);
  
  
  const listItems = data.map(seat =>
  <>
    <div
      class={"desk border border-primary rounded-circle text-bg-primary bg-gradient opacity-75 z-0" + " " + seat.DeskId}
    >
    </div>
    <div class={"desk rounded-circle opacity-100 z-3" + " " + seat.DeskId} >
      <div class="position-relative w-100 h-100">
        <span class="opacity-100 text-white position-absolute top-50 start-50 translate-middle fs-6 text-nowrap">
          {seat.UserName.substr(0,3)}
        </span>
      </div>
    </div>
  </>
  );

  // ここを変更
  return (
    <>
      <div>
        {listItems}
        
      </div>
     </>
  );
};

export default Test;
