import './App.css';
import RoadData from './RoadData';
import * as React from 'react';

function App() {

  return (

    <div className="App">
      <head>
      </head>
      <body>
        <nav class="navbar bg-dark navbar-expand-lg">
            <div class="container-fluid">
              <h2 class="navbar-brand text-light fs-4 align-baseline pb-0 wf-roundedmplus1c" style={{"letter-spacing":"0.1em"}}>
                共済保険ソリューション事業部座席表
              </h2>
              <span class="navbar-text text-white-50">powered by  <i class="fas fa-code me-1"></i>遊学団 </span>
            </div>
        </nav>
        <div class="seats">
          <img src="seat.png" class="rounded float-start"　alt="代替テキスト"></img>
          <RoadData />
　      </div>



      </body>
    </div>
  );
}

export default App;
